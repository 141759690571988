.domains {
    margin-top: 8px;

    &__wrap {
        position: relative;
        margin-top: 30px;
    }

    &__container {
        display: flex;
    }

    &__list {
        display: flex;
    }

    &__item {
        display: flex;
        gap: 10px;
        margin-bottom: 8px;
        align-items: center;
        margin-left: 30px;
        .ant-form-item-label {
            position: absolute;
            top: -40px;
            label:after {
                content: "";
            }
        }
        &:not(:first-child) {
            .ant-form-item-label {
                display: none;
            }
        }
        &-error {
            margin-bottom: 15px;
        }
        & .ant-form-item-explain-error {
            font-size: 10px;
        }
    }

    &__select {
        margin-bottom: 0;
        & .ant-row {
            width: 122px;
        }
    }

    &__item-input {
        width: 100%;
        margin-bottom: 0;
    }

    &__add-message-btn {
        background: $secondary10;
        flex: 0 0 30px;
        height: 28px;
    }

    &__delete-btn {
        background: $removeColor;
        flex: 0 0 30px;
        height: 28px;
    }

    &__item-index {
        position: absolute;
        left: 0;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        color: $black20;
        opacity: 0.7;
    }

    &__add-btn {
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        color: $secondary;
    }

    .ant-col > div:last-child {
        height: 0;
    }
}
