.kyc-form {
  .droppable-list__droppable-item {
    background: white;
  }

  .droppable-list__droppable-item {
    border-radius: 5px;
  }

  .droppable-list__item-container {
    padding-bottom: 12px;
  }

  .btn-group-switcher {
    width: 100%;
    margin-bottom: 12px;
    button {
      width: 50%;
      justify-content: center;

      &:only-child {
        width: 100%;
      }
    }
  }

  .ant-tabs-content-holder {
    padding-bottom: 4px;
  }

  .ant-tabs-tab-btn {
    padding: 0 5px;
  }

  &-internal {
    &__tabs {
      transform: translate(-58px, 0);
      width: calc(100% + 58px);
    }
    &__title {
      font-size: 14px;
      font-weight: 600;
      line-height: 22px;
    }
    &__header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin: 12px 0;
    }
    .ant-tabs-tab {
      width: auto !important;
      &:not(:first-child) {
        margin-left: 8px !important;
      }
    }
  }
}
