.zero-bounce-form {
  .ant-input-number-group {
    .ant-input-number {
      max-width: 100px;
    }

    & > .ant-input-number {
      &:first-child,
      &:last-child {
        border-radius: 6px;
      }
    }

    .ant-input-number-group-addon {
      &:first-child,
      &:last-child {
        border: none;
        background: transparent;
      }

      &:first-child {
        padding-left: 0;
      }
    }
  }
}
